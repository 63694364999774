video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -101;
}

text {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  font-size: 5em;
  color: #fff;
  animation: glitch 0.5s infinite;
}
